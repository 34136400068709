import React from "react"
import { Text } from "theme-ui"
import Layout from "../../components/layout"
import RoleTemplate from "../../components/roles/RoleTemplate"
import Pagination from "../../components/Pagination"

import portraitImage from "../../images/role-portraits/spy-cutout.png"
import spy01Image from "../../images/ability-cards/spy-01.png"
import spy02Image from "../../images/ability-cards/spy-02.png"
import spy03Image from "../../images/ability-cards/spy-03.png"
import spy04Image from "../../images/ability-cards/spy-04.png"

import YAMLData from "../../data/learning-paths/spy.yaml"

const SpyPage = () => (
  <Layout>
    <RoleTemplate
      name="spy"
      src={portraitImage}
      bgGradient="45deg, #EFDFEC, #DFD0E4"
      introDescription={
        <>
          <Text>The Spy smiles and offers an uncanny greeting, walking through the front door with confidence. They slip in and out of the shadows, striking foes when they least expect it. They craft remarkable, bespoke tools that give them an edge in their pursuits.</Text>
          <Text>You should play The Spy if you want to roleplay a supremely skilled character who relies on practical means to achieve their aims. It’s a great choice for playing a secret agent or roguish assassin.</Text>
        </>
      }
      imgWidth={500}
      imgLeftPos="auto"
      imgRightPos={[4, 6, 8]}
      abilityDescription="The Spy is Quest’s take on the classic rogue archetype, which you can use to play any kind of sneaky and skilled character. This role has the unique capability to create and use magic gadgets. Here are some of our favorite Spy abilities:"
      abilityImg01={spy01Image}
      abilityImg02={spy02Image}
      abilityImg03={spy03Image}
      abilityImg04={spy04Image}
      yaml={YAMLData}
    />

    <Pagination parent="roles" prev="doctor" next="magician" />
  </Layout>
)

export default SpyPage
